<template>
  <DataTable
    class="mx-4"
    :headers="headers"
    :pagination.sync="pagination"
    :objects="records"
    :loading="loading"
    hideToolBar
  >
    <template v-slot:cell="{ header, objects }">
      <component
        :is="cellFactory(header)"
        :header="header"
        :item="objects.item"
        @remove="item => $emit('remove', item)"
        @edit="item => $emit('edit', item)"
        @pair="item => $emit('pair', item)"
      ></component>
    </template>
  </DataTable>
</template>

<script>
export default {
  props: {
    loading: {},
    records: {},
    noPairs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Nazwa",
          align: "left",
          sortable: true,
          value: "text"
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "other"
        }
      ],
      pagination: {
        sortBy: 'text',
        descending: false,
        rowsPerPage: -1
      },
    };
  },
  components: {
    DataTable: () => import("@/components/tables/DataTable"),
    DefaultCell: () => import("./DefaultCell"),
    TableCell: () => import("./TableCell"),
    EditButtonsCell: () => import("@/components/tables/EditButtonsCell"),
    PredefinedToolsCell: () => import("@/components/tables/predefined/PredefinedToolsCell"),
    ServiceLabelCell: () => import("./ServiceLabelCell"),
    PredefinedFieldCel: () => import("@/components/tables/predefined/PredefinedFieldCell"),
  },
  methods: {
    cellFactory(header) {
      switch (header.text) {
        case "Nazwa":
          return "PredefinedFieldCel";
        case "":
          if (this.noPairs) {
            return "EditButtonsCell";
          } else {
            return "PredefinedToolsCell";
          }
        default:
          return "DefaultCell";
      }
    },
  }
};
</script>

